export const projectsData = [
  {
    id: 1,
    title: "MyPline",
    date: "Novembre 2024",
    languages: ["Symfony", "MySql", "Twig", "JavaScript", "ChartJS"],
    infos:
      "Cette application de développement personnel, développée avec Symfony, est conçue pour offrir aux utilisateurs une expérience de paramétrage et de suivi entièrement personnalisée. L’objectif est de permettre à chacun de gérer ses routines et de progresser vers ses objectifs au quotidien.",
    img: "./assets/img/MyPline.png",
    link: "https://www.mypline.com",
  },
  {
    id: 2,
    title: "En cours de développement",
    date: "Bientôt",
    languages: ["Symfony", "JavaScript"],
    infos: "En cours de développement",
    img: "./assets/img/in-progress.png",
    link: "http://www.google.com",
  },
  {
    id: 3,
    title: "Everpost",
    date: "Avril 2020",
    languages: ["Wordpress", "Php", "React"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-3.jpg",
    link: "http://www.google.com",
  },
  {
    id: 4,
    title: "Creative Dev",
    date: "Juillet 2020",
    languages: ["Vue", "Php"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-4.jpg",
    link: "http://www.google.com",
  },
  {
    id: 5,
    title: "MyPline",
    date: "Novembre 2024",
    languages: ["Symfony", "MySql", "Twig", "JavaScript", "ChartJS"],
    infos:
      "Cette application de développement personnel, développée avec Symfony, est conçue pour offrir aux utilisateurs une expérience de paramétrage et de suivi entièrement personnalisée. L’objectif est de permettre à chacun de gérer ses routines et de progresser vers ses objectifs au quotidien.",
    img: "./assets/img/mypline.jpg",
    link: "https://www.mypline.com",
  },
];
